import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import * as JsSearch from 'js-search'
import { getContentReleaseBlock } from '../../utils/helpers'
import { totalCourseReleases } from '../../utils/siteConfig'
import TextField from '@material-ui/core/TextField'

const StyledTextField = styled(TextField)`
  ${props => props.theme.materialTextField()}
`

class SearchForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      terms: '',
    }
    this.onChange = this.onChange.bind(this)
    this.delayTimer = 0
    this.inputRef = React.createRef()
  }

  UNSAFE_componentWillMount() {
    const { data } = this.props
    const { subtopics } = data.contentfulCourse

    const flattenTag = subtopic => {
      const tagList = subtopic.tag
        ? subtopic.tag.map(item => {
            return item.title
          })
        : []
      subtopic['tags'] = tagList
      return subtopic
    }
    const tweakedSubtopics = subtopics.map(flattenTag)

    this.search = new JsSearch.Search('code')
    this.search.addIndex('code')
    this.search.addIndex('title')
    this.search.addIndex('tags')
    this.search.addDocuments(tweakedSubtopics)
  }

  onChange(e) {
    e.preventDefault()
    const self = this
    const terms = e.target.value
    clearTimeout(this.delayTimer)
    this.delayTimer = setTimeout(() => {
      self.setState({
        terms: terms,
      })
    }, 500)
  }

  getResults() {
    const { authUser } = this.props
    const { terms } = this.state
    const results = this.search.search(terms)

    const currentRelease = getContentReleaseBlock(
      authUser.intake.startDate,
      authUser.intake.endDate,
      totalCourseReleases,
      authUser
    )

    let list, content
    if (results.length > 0) {
      list = results.map(function(result, i) {
        const key = `result-${i}`

        const { releaseTiming } = result

        let isActive = false
        if (releaseTiming) {
          if (currentRelease >= releaseTiming) {
            isActive = true
          }
        }

        const url = `/graphic-design/${result.slug}`
        if (isActive) {
          return (
            <Result key={key} active={true}>
              <Link to={url}>
                {result.title} <em>{result.code}</em>
              </Link>
            </Result>
          )
        } else {
          return (
            <Result key={key} active={false}>
              <span>
                {result.title} <em>{result.code}</em>
              </span>
            </Result>
          )
        }
      })
      content = <Results>{list}</Results>
    }
    return content
  }

  render() {
    const resultContent = this.getResults()

    return (
      <>
        <StyledTextField
          label="Code, topic or keyword"
          name="search"
          onChange={this.onChange}
          margin="normal"
          autoComplete="off"
          ref={this.inputRef}
          autoFocus={true}
        />
        {resultContent}
      </>
    )
  }
}

export default SearchForm

const Results = styled.div`
  width: 75%;
  margin-top: 3em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    width: 100%;
  }
`

const Result = styled.div`
  border-bottom: 3px solid ${props => props.theme.colors.greyDark2};
  width: calc(50% - 3px);
  margin-right: 3px;
  &:first-child {
    border-top: 3px solid ${props => props.theme.colors.greyDark2};
  }
  &:nth-child(2) {
    border-top: 3px solid ${props => props.theme.colors.greyDark2};
  }
  &:nth-of-type(even) {
    margin-right: 0px;
  }
  a,
  span {
    color: ${props =>
      props.active ? props.theme.colors.white : props.theme.colors.greyDark2};
    display: block;
    padding: 15px 0;
    margin-bottom: 2px;
    text-decoration: none;
    em {
      color: #4a4a4a;
      text-transform: uppercase;
      margin-left: 5px;
      font-style: normal;
    }
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    width: 100%;
    &:nth-of-type(odd) {
      margin-right: 0px;
    }
    &:nth-child(2) {
    border-top: none};
  }
  }
`
