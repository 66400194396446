import moment from 'moment'

const getContentReleaseBlock = (
  startDate,
  endDate,
  totalReleases,
  authUser
) => {
  if (authUser && authUser.roles && authUser.roles.admin === true) {
    return totalReleases
  } else {
    const startMoment = moment(startDate, 'X')
    const endMoment = moment(endDate, 'X')
    const todayMoment = moment()

    console.log(
      `Start: ${startMoment.format('DD-MM-YY')}`,
      `End: ${endMoment.format('DD-MM-YY')}`
    )

    const intakeDuration = moment.duration(endMoment.diff(startMoment))
    const totalIntakeDays = intakeDuration.asDays()

    const todayDuration = moment.duration(todayMoment.diff(startMoment))
    const todaysIntakeDay = todayDuration.asDays()

    const progress = todaysIntakeDay / totalIntakeDays

    console.log(
      `totalIntakeDays: ${totalIntakeDays}`,
      `todaysIntakeDay: ${todaysIntakeDay}`,
      `progress: ${progress}`
    )

    let releaseBlock = 0

    if (progress < 0) {
      // Batch 2 released 1.5 days prior
      if (todaysIntakeDay >= -1.5) {
        releaseBlock = 2
      }
      // Batch 1 released 14.5 days prior
      else if (todaysIntakeDay >= -14.5) {
        releaseBlock = 1
      }
    } else {
      // Batch 4 released 75% through course
      if (progress >= 0.75) {
        releaseBlock = 4
      }
      // Batch 3 released 25% through course
      else if (progress >= 0.15) {
        releaseBlock = 3
      }
      // Backup because course is active (progress > 0) should be release 2 :)
      else {
        releaseBlock = 2
      }
    }
    console.log('releaseBlock', releaseBlock)

    return releaseBlock
  }
}

const filterByStartDateAndEndDate = (data, startDate, endDate) => {
  if (data.docs.length > 0) {
    return data.docs.filter(el => {
      const intakeStartDateTs = moment
        .unix(parseInt(el.data().startDate.seconds))
        .year()
      const intakeEndDateTs = parseInt(el.data().endDate.seconds)
      const endDateTs = parseInt(moment(endDate).format('X'))

      const filterSameYear =
        intakeStartDateTs != parseInt(startDate - 1) ||
        moment.unix(parseInt(el.data().endDate.seconds)).year() !=
          parseInt(startDate - 1)

      if (
        intakeEndDateTs < endDateTs &&
        intakeStartDateTs <= startDate &&
        filterSameYear
      ) {
        return el
      }
    })
  } else {
    return []
  }
}

const getCurrencySymbol = currency => {
  return currency.toLowerCase() === 'gbp' ? '£' : '$'
}

export {
  getContentReleaseBlock,
  filterByStartDateAndEndDate,
  getCurrencySymbol,
}
