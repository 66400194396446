import React from 'react'
import { Flex, Box } from 'reflexbox'
import styled from 'styled-components'

export default ({ children }) => {
  return (
    <Wrap>
      <Flex flexWrap={['wrap', 'nowrap']}>
        <Box
          width={[1, 3 / 5]}
          mr={[0, `${(1 / 5) * 100}%`]}
          ml={[0, `${(1 / 5) * 100}%`]}
          mb={[1, 5]}
        >
          {children}
        </Box>
      </Flex>
    </Wrap>
  )
}

const Wrap = styled.div`
  padding-top: 170px;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    padding-top: 150px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    padding-top: 100px;
    padding-bottom: 80px;
  }
`
