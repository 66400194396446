import React from 'react'
import { graphql } from 'gatsby'
import { compose } from 'recompose'
import { withAuthorization } from '../components/session'

import SEO from '../components/SEO'
import { Wrap } from '../components/page/components'
import FocusHeader from '../components/global/focus-header'
import FocusBody from '../components/page/focus-body'

import SearchForm from '../components/search/form'

const SearchBase = ({ data, authUser, location }) => {
  const backUrl =
    location.state && location.state.prevPath ? location.state.prevPath : '/'

  return (
    <Wrap>
      <SEO title="Search" />
      <FocusHeader title="Search" backUrl={backUrl} />
      <FocusBody>
        <SearchForm authUser={authUser} data={data} />
      </FocusBody>
    </Wrap>
  )

  // return (
  //   <AuthUserContext.Consumer>
  //     {({ authUser }) =>
  //       authUser ? <SearchForm authUser={authUser} data={data} /> : null
  //     }
  //   </AuthUserContext.Consumer>
  // )
}

export const query = graphql`
  query {
    contentfulCourse(slug: { eq: "graphic-design-course" }) {
      topics {
        title
        slug
      }
      subtopics {
        title
        id
        slug
        releaseTiming
        publishDate
        code
        isActive
        topic {
          id
          slug
          title
        }
        tag {
          title
          slug
        }
      }
    }
  }
`

const condition = authUser => !!authUser
const Search = compose(withAuthorization(condition))(SearchBase)
export default Search
