import React from 'react'
import { withTheme } from 'styled-components'

const IconArrow = ({ theme, fill }) => {
  const iconFill = fill ? fill : theme.materialTheme.fg;

  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.3623 15.6055L9.0752 7.89258L1.3623 0.179688L0.137695 1.39355L6.63672 7.89258L0.137695 14.3916L1.3623 15.6055Z"
        fill={iconFill}
      />
    </svg>
  )
}

export default withTheme(IconArrow)
