import React from 'react'
import { Link } from 'gatsby'
import { Flex, Box } from 'reflexbox'
import styled from 'styled-components'
import { Back, HideMob } from '../page/components'
import LogomarkHorizontal from '../svg/logomark-horizontal'
import IconArrow from '../svg/icon-arrow'
import GridMarks from './grid-marks'
import theme from '../../styles/theme'

export default ({ title, backUrl, hideBackUrl, titleLink, subTitle }) => {
  // subTitle = 'Change Email'

  const validBackUrl = backUrl ? backUrl : '/'

  const backButton = hideBackUrl ? null : <Back url={validBackUrl} />

  let titleContent
  if (titleLink) {
    titleContent = <Link to={titleLink}>{title}</Link>
  } else {
    titleContent = title
  }

  let subTitleContent
  if (subTitle) {
    subTitleContent = (
      <>
        <Sep>
          <IconArrow fill={theme.colors.white} />
        </Sep>
        {subTitle}
      </>
    )
  }

  return (
    <Header>
      <GridMarks />
      <Flex flexWrap="nowrap" width={['100%']}>
        <Box width={[1, 1 / 5]} display="flex" alignItems="center">
          <Link to={validBackUrl}>
            <LogomarkHorizontal />
          </Link>
        </Box>
        <Box width={[0, 3 / 5]} display="flex" alignItems="center">
          <HideMob>{titleContent} {subTitleContent}</HideMob>
        </Box>
        <Box
          width={[0, 1 / 5]}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          ml="auto"
        >
          {backButton}
        </Box>
      </Flex>
    </Header>
  )
}

const Header = styled.div`
  height: 100px;
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 500;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  align-items: center;
  a {
    color: ${props => props.theme.colors.white};
    text-decoration: none;
  }
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    height: 76px;
    padding-left: 35px;
    padding-right: 35px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    padding-left: 14px;
    padding-right: 14px;
  }
`

const Sep = styled.span`
  padding: 0 10px;
  display: inline-block;
  
  svg {
    position: relative;
    top: 2px;
  }
`